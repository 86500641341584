<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.ordem_servico.titulos.os_parcial')"
      sem-filtro
      sem-botoes
      sem-registros
    />
    <div class="os-parcial-tabelas">
      <div class="os-principal-tabelas">
        <div>
          <v-chip
            class="mt-1 mb-4"
            small
          >
            Ordem de Serviço: {{ `${form.codigo}.1` }}
          </v-chip>
        </div>
        <v-form
          ref="form-os-principal"
          class="row mb-1"
        >
          <input-date
            v-model="form.dataPrevisaoEntrega"
            class="col-12 col-md-6"
            :label="$t('modulos.ordem_servico.os_parcial.data_entrega')"
            :placeholder="$t('geral.inputs.selecione')"
            disabled
          />
          <input-select
            v-model="form.prioridadeId"
            :options="opcoes.prioridade"
            :label="$t('modulos.ordem_servico.os_parcial.prioridade')"
            :placeholder="$t('geral.inputs.selecione')"
            class="col-12 col-md-6"
            clearable
          />
        </v-form>
        <tabela-padrao-prime-vue
          v-model="tabelas.osOriginal.selecionados"
          :colunas="tabelas.osOriginal.colunas"
          :dados="tabelas.osOriginal.dados"
          :mostrar-acoes="false"
          sem-paginacao
          ajustar-altura-linha
        >
          <template v-slot:situacao="{ slotProps }">
            <div class="d-flex justify-center">
              <icone-padrao
                :icone="getSituacaoIcon(slotProps.data.situacao)"
                :tooltip="getSituacaoMessage(slotProps.data.situacao)"
              />
            </div>
          </template>
        </tabela-padrao-prime-vue>
      </div>
      <div class="os-botoes">
        <v-divider class="mr-2" />
        <botao-padrao @click="adicionarOuRemover('adicionar')">
          <v-icon>$arrowDown</v-icon>
        </botao-padrao>
        <botao-padrao
          outlined
          color="secondary"
          @click="adicionarOuRemover('remover')"
        >
          <v-icon>$arrowUp</v-icon>
        </botao-padrao>
        <v-divider class="ml-2" />
      </div>
      <div class="os-principal-tabelas">
        <div>
          <v-chip
            class="mt-1 mb-4"
            small
          >
            Ordem de Serviço: {{ `${form.codigo}.2` }}
          </v-chip>
        </div>
        <v-form
          ref="form-os-principal"
          class="row mb-1"
        >
          <input-date
            v-model="form.dataPrevisaoEntregaNova"
            class="col-12 col-md-6"
            :label="$t('modulos.ordem_servico.os_parcial.data_entrega')"
            :placeholder="$t('geral.inputs.selecione')"
            apenas-datas-futuras
            disabled
          />
          <input-select
            v-model="form.prioridadeOsParcial"
            :options="opcoes.prioridade"
            :label="$t('modulos.ordem_servico.os_parcial.prioridade')"
            :placeholder="$t('geral.inputs.selecione')"
            class="col-12 col-md-6"
            clearable
          />
        </v-form>
        <tabela-padrao-prime-vue
          v-model="tabelas.osParcial.selecionados"
          :colunas="tabelas.osParcial.colunas"
          :dados="tabelas.osParcial.dados"
          :mostrar-acoes="false"
          sem-paginacao
          ajustar-altura-linha
        >
          <template v-slot:situacao="{ slotProps }">
            <div class="d-flex justify-center">
              <icone-padrao
                :icone="getSituacaoIcon(slotProps.data.situacao)"
                :tooltip="getSituacaoMessage(slotProps.data.situacao)"
              />
            </div>
          </template>
        </tabela-padrao-prime-vue>
      </div>
    </div>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        <v-icon class="mr-1">
          $mdiCancel
        </v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao @click="confirmacaoSalvar">
        <v-icon class="mr-1">
          $mdiContentSaveOutline
        </v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>

<script>
import PrioridadeService from '@/common/services/cadastros/PrioridadeService';
import { DropdownModel } from '@/common/models/geral/DropdownModel';
import CabecalhoPagina from '@/components/layout/CabecalhoPagina.vue';
import OrdemServicoService from '@/common/services/cadastros/OrdemServicoService.js';
import { OrdemServicoParcialModel } from '@/common/models/cadastros/OrdemServicoParcialModel.js';
import helpers from '@/common/utils/helpers';
import { enumOperacaoSituacaoIcons } from '@enums/icons';
export default {
  name: 'OrdemServicoParcial',
  components: {
    CabecalhoPagina,
  },
  props: ['id'],
  data() {
    return {
      form: new OrdemServicoParcialModel({}),
      tabelas: {
        osOriginal: {
          dados: [],
          colunas: [
            {
              value: 'codigoTag',
              text: this.$t('modulos.ordem_servico.os_parcial.tabela.codigo'),
              sortable: false,
            },
            {
              value: 'tipoInstrumento.nome',
              text: this.$t(
                'modulos.ordem_servico.os_parcial.tabela.tipo_instrumento'
              ),
              sortable: false,
            },
            {
              value: 'situacao',
              text: this.$t('modulos.ordem_servico.os_parcial.tabela.situacao'),
              sortable: false,
            },
            {
              value: 'setor.nome',
              text: this.$t('modulos.ordem_servico.os_parcial.tabela.setor'),
              sortable: false,
            },
            {
              value: 'prazoEntrega',
              text: this.$t('modulos.ordem_servico.os_parcial.tabela.prazo'),
              sortable: false,
              formatter: (value) => {
                if (!value) return 'Sem Prazo';
                return value;
              },
            },
          ],
          selecionados: [],
        },
        osParcial: {
          dados: [],
          colunas: [
            {
              value: 'codigoTag',
              text: this.$t('modulos.ordem_servico.os_parcial.tabela.codigo'),
              sortable: false,
            },
            {
              value: 'tipoInstrumento.nome',
              text: this.$t(
                'modulos.ordem_servico.os_parcial.tabela.tipo_instrumento'
              ),
              sortable: false,
            },
            {
              value: 'situacao',
              text: this.$t('modulos.ordem_servico.os_parcial.tabela.situacao'),
              sortable: false,
            },
            {
              value: 'setor.nome',
              text: this.$t('modulos.ordem_servico.os_parcial.tabela.setor'),
              sortable: false,
            },
            {
              value: 'prazoEntrega',
              text: this.$t('modulos.ordem_servico.os_parcial.tabela.prazo'),
              sortable: false,
              formatter: (value) => {
                if (!value) return 'Sem Prazo';
                return value;
              },
            },
          ],
          selecionados: [],
        },
      },
      opcoes: {
        prioridade: [],
        situacao: helpers.SituacaoOrdemServicoEnum,
      },
    };
  },
  watch: {
    'tabelas.osParcial.dados': {
      handler() {
        this.atualizarDataPrevisaoEntregaParcial();
      },
      deep: true,
    },
  },
  async mounted() {
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.ordem_servico.titulos.os_parcial_titulo')
    );
    this.buscarPrioridade();
    await this.buscarOrdemServico(this.id);
  },
  methods: {
    buscarPrioridade: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      PrioridadeService.listar()
        .then((res) => {
          this.opcoes.prioridade = new DropdownModel(res.data.items);
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscarOrdemServico: async function (id) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      await OrdemServicoService.buscar(id)
        .then((res) => {
          this.tabelas.osOriginal.dados = res.data.ordensServicosInstrumentos;
          this.form = new OrdemServicoParcialModel(res.data);
          this.form.dataPrevisaoEntregaNova = res.data.dataPrevisaoEntrega;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    adicionarOuRemover: function (acao) {
      const { osOriginal, osParcial } = this.tabelas;

      if (acao === 'adicionar') {
        osOriginal.selecionados.forEach((instrumento) => {
          osParcial.dados.push(instrumento);
          osOriginal.dados.splice(osOriginal.dados.indexOf(instrumento), 1);
        });
      }
      if (acao === 'remover') {
        osParcial.selecionados.forEach((instrumento) => {
          osOriginal.dados.push(instrumento);
          osParcial.dados.splice(osParcial.dados.indexOf(instrumento), 1);
        });
      }

      osOriginal.selecionados = [];
      osParcial.selecionados = [];
    },
    confirmacaoSalvar() {
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    salvar: function () {
      this.form.ordemServicoInstrumentos = this.tabelas.osParcial.dados;
      this.$store.dispatch('Layout/iniciarCarregamento');
      OrdemServicoService.criarOrdemServicoParcial(this.id, this.form.request)
        .then(() => {
          this.toastSucesso(
            this.$t('modulos.ordem_servico.cadastro_sucesso_parcial')
          );

          this.$router.push({
            name: this.$route.query?.retorno || 'ordem-servico',
          });
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar() {
      this.confirmarCancelar().then(() => {
        this.$router.push({
          name: this.$route.query?.retorno || 'ordem-servico',
        });
      });
    },
    getSituacaoIcon(situacao) {
      if (situacao) {
        const icon = enumOperacaoSituacaoIcons.find(
          (el) => el.value === situacao
        ).icon;
        return this.$vuetify.icons.values[icon];
      }
    },
    getSituacaoMessage(situacao) {
      if (situacao) {
        return enumOperacaoSituacaoIcons.find((el) => el.value === situacao)
          .message;
      }
    },
    atualizarDataPrevisaoEntregaParcial: function () {
      this.form.ordemServicoInstrumentos = this.tabelas.osParcial.dados;
      this.$store.dispatch('Layout/iniciarCarregamento');
      OrdemServicoService.atualizarDataPrevisaoEntregaParcial(
        this.id,
        this.form.atualizarDataPrevisaoEntregaParcialRequest
      )
        .then((res) => {
          if (res.data.prazoEntrega)
            this.form.dataPrevisaoEntregaNova = res.data.prazoEntrega;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.os-parcial-tabelas {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  .os-principal-tabelas {
    flex-direction: column;
  }
  .os-botoes {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
}
</style>
