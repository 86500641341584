import _ from 'lodash';
export class OrdemServicoParcialModel {
  constructor({
    id,
    codigo,
    dataPrevisaoEntrega,
    dataPrevisaoEntregaNova,
    prioridadeId,
    prioridadeOsParcial,
    ordemServicoInstrumentos = []
  }) {
    this.id = id;
    this.codigo = codigo;
    this.dataPrevisaoEntrega = dataPrevisaoEntrega;
    this.dataPrevisaoEntregaNova = dataPrevisaoEntregaNova;
    this.prioridadeId = prioridadeId;
    this.prioridadeOsParcial = prioridadeOsParcial;
    this.ordemServicoInstrumentos = ordemServicoInstrumentos;
  }
  get request() {
    let retorno = _.cloneDeep(this);
    retorno.ordemServicoInstrumentosIds = retorno.ordemServicoInstrumentos.map(item => item.id);
    retorno.prioridadeIdOSOriginal = retorno.prioridadeId;
    retorno.prioridadeIdOSNova = retorno.prioridadeOsParcial;
    return retorno;
  }

  get atualizarDataPrevisaoEntregaParcialRequest() {
    let retorno = _.cloneDeep(this);
    retorno.ordemServicoInstrumentosIds = retorno.ordemServicoInstrumentos.map(item => item.id);

    return retorno;
  }
}
